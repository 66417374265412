import { render, staticRenderFns } from "./MotorbikeManagementPage.vue?vue&type=template&id=d131bdda&scoped=true&"
import script from "./MotorbikeManagementPage.vue?vue&type=script&lang=js&"
export * from "./MotorbikeManagementPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d131bdda",
  null
  
)

export default component.exports