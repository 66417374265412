<template>
  <div>
    <upload-file-excel-widget @uploadSuccess="onUploadFile" />
    <div
      v-if="itemsUpload.length !== 0"
      class="position-relative"
    >
      <table-bt-widget
        :rows="itemsUpload"
        :columns="columns"
        :show-add-button="false"
        :loading="itemsUploadLoading"
        view-able
        delete-able
        edit-able
        @clickView="onClickViewItemsUpload"
        @clickDelete="onClickDeleteItemsUpload"
        @clickEdit="onClickEditItemsUpload"
      >
        <template #headerTitle>
          <div class="d-flex align-items-center mb-50 mb-sm-0">
            <h5 class="m-0 mr-50">ข้อมูลรอการอัปโหลดจากไฟล์ {{ fileUploadName }}</h5>
            <button-loading-widget
              icon="XIcon"
              variant="danger"
              class="btn-icon mr-50"
              style="padding: 0.3rem"
              size="sm"
              :loading="itemsUploadLoading"
              @click="onClickCancelUpload"
            />
            <button-loading-widget
              icon="CheckIcon"
              variant="success"
              class="btn-icon"
              style="padding: 0.3rem"
              size="sm"
              :loading="itemsUploadLoading"
              @click="onSaveUploadFile"
            />
          </div>
        </template>

        <template #usable="{ data }">
          <b-badge
            v-if="data.value"
            variant="success"
          >
            เปิด
          </b-badge>
          <b-badge v-else> ปิด </b-badge>
        </template>
      </table-bt-widget>
    </div>

    <table-bt-widget
      :loading="loading"
      :rows="items"
      :columns="columns"
      title="ข้อมูลรถ"
      view-able
      edit-able
      delete-able
      @clickView="onClickEditOrView"
      @clickEdit="data => onClickEditOrView(data, true)"
      @clickDelete="onClickDelete"
      @clickAdd="onClickAdd"
    >
      <template #usable="{ data }">
        <b-badge
          v-if="data.value"
          variant="success"
        >
          เปิด
        </b-badge>
        <b-badge v-else> ปิด </b-badge>
      </template>
    </table-bt-widget>
    <b-modal
      ref="refModalCreate"
      size="xl"
      title="ข้อมูลรถ"
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="!canEditForm"
      ok-only
      :ok-title="formMotorbike.id ? 'แก้ไข' : 'เพิ่ม'"
      @ok="onModalConfirm"
    >
      <form-motorbike
        ref="refFormMotorbike"
        :form-data="formMotorbike"
        :can-edit="canEditForm"
      />
    </b-modal>

    <b-modal
      ref="refModalCreateLocal"
      size="xl"
      title="ข้อมูลรถ"
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="!canEditForm"
      ok-only
      ok-title="แก้ไข"
      @ok="onModalLocalConfirm"
    >
      <form-motorbike
        ref="refFormMotorbikeLocal"
        :form-data="formMotorbike"
        :can-edit="canEditForm"
      />
    </b-modal>

    <!-- <pre>{{ formMotorbike }}</pre> -->
    <!-- <pre>{{ itemsUpload }}</pre> -->
  </div>
</template>

<script>
import FormMotorbike from './components/FormMotorbike.vue'

export default {
  components: {
    FormMotorbike,
  },
  data() {
    return {
      loading: false,
      items: [],
      columns: [
        { key: 'car_model', label: 'โมเดล', sortable: true },
        { key: 'frame_number', label: 'เลขเฟรม' },
        { key: 'car_name_en', label: 'ชื่ออังกฤษ' },
        { key: 'car_name_th', label: 'ชื่อไทย' },
        {
          key: 'car_size',
          label: 'ไซส์',
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: value => `${value}`.toUpperCase(),
        },
        {
          key: 'selling_price',
          label: 'ราคา',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: value => this.gFormatNumberToCurrency(value),
        },
        { key: 'usable', label: 'สถานะแสดง', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
      ],
      formMotorbike: {},
      canEditForm: false,
      itemsUpload: [],
      fileUploadName: '',
      keyUploadFileRequired: [
        'ACTCOST',
        'COSTPRICE',
        'FRAMENUMBER',
        'IMAGEID',
        'MODEL',
        'MOTORBIKE_TH',
        'MOTORBIKE_EN',
        'PRICE',
        'REGISTERCOST',
        'SIZE',
        'TRANFERCOST',
        'USABLE',
      ],
      itemsUploadLoading: false,
    }
  },
  created() {
    this.getMotorbike()
  },
  methods: {
    async getMotorbike() {
      this.loading = true
      const resp = await this.$http.get('/api/finance-car-data').catch(() => null)
      // console.log('getMotorbike', resp)
      if (resp && resp.code === 200) {
        this.items = [...resp.data]
      }
      this.loading = false
    },
    onClickEditOrView(row, canEdit = false) {
      const { item } = row
      if (canEdit) {
        this.canEditForm = true
      } else {
        this.canEditForm = false
      }
      this.formMotorbike = { ...item }
      this.$refs.refModalCreate.show()
    },
    async onClickDelete(row) {
      const { item } = row
      const confirm = await this.gCheckConfirmV2(`ลบข้อมูล ${item.car_model} (${item.car_name_th})`)
      if (!confirm) return
      this.loading = true
      // await this.gMockAsync(500)
      const resp = await this.$http.delete(`/api/finance-car-data/${item.id}`).catch(() => null)
      // console.log('onClickDelete', resp)
      if (resp && resp.code === 200) {
        this.items = [...this.items].filter(v => v.id !== item.id)
      }
      this.loading = false
    },
    onClickAdd() {
      this.canEditForm = true
      this.resetForm()
      this.$refs.refModalCreate.show()
    },
    resetForm() {
      this.formMotorbike = {
        car_model: '',
        frame_number: '',
        car_name_th: '',
        car_name_en: '',
        car_size: 'S',
        selling_price: 0,
        image_id: 0,
        cost_price: 0,
        register_cost: 0,
        act_cost: 0,
        transfer_cost: 0,
        usable: true,
      }
    },
    async onModalConfirm(bvEvent) {
      bvEvent.preventDefault()
      const validate = await this.$refs.refFormMotorbike.validateForm()
      if (!validate) return
      const form = this.$refs.refFormMotorbike.getFormData()

      let titleConfirm = 'ยืนยันเพิ่มข้อมูล'
      if (form.id) {
        titleConfirm = 'ยืนยันแก้ไขข้อมูล'
      }

      const confirm = await this.gCheckConfirmV2(titleConfirm)
      if (!confirm) return
      this.$refs.refModalCreate.hide()

      this.loading = true
      if (form.id) {
        const respEdit = await this.$http.put(`api/finance-car-data/${form.id}`, form).catch(() => null)
        // console.log('edit', respEdit)
        if (respEdit && respEdit.code === 200) {
          this.items = [...this.items].map(v => {
            if (v.id === respEdit.data.id) {
              return { ...respEdit.data }
            }

            return { ...v }
          })
        }
      } else {
        const respCreate = await this.$http.post('api/finance-car-data', form).catch(() => null)
        // console.log('add', respCreate)
        if (respCreate && respCreate.code === 200) {
          await this.getMotorbike()
        }
      }
      this.loading = false
    },
    onUploadFile(data, file) {
      const firstData = [...data][0]

      const checkFileValid = this.keyUploadFileRequired.every(value => value in firstData)

      if (!checkFileValid) {
        this.gDisplayToast('โปรดตรวจสอบไฟล์', 'รูปแบบข้อมูลในไฟล์ที่อัปโหลดไม่ถูกต้อง', 'danger')
        return
      }

      // eslint-disable-next-line prefer-destructuring
      this.fileUploadName = `${file.name}`.split('.')[0]
      const validFileDataList = []
      this.itemsUpload = [...data].map((v, i) => {
        const {
          ACTCOST,
          COSTPRICE,
          FRAMENUMBER,
          IMAGEID,
          MODEL,
          MOTORBIKE_TH,
          MOTORBIKE_EN,
          PRICE,
          REGISTERCOST,
          SIZE,
          TRANFERCOST,
          USABLE,
        } = v

        const newObj = {
          local_id: i + 1,
          car_model: MODEL,
          car_name_th: MOTORBIKE_TH,
          car_name_en: MOTORBIKE_EN,
          car_size: `${SIZE}`.toUpperCase(),
          frame_number: FRAMENUMBER,
          selling_price: Number(PRICE ?? 0),
          image_id: IMAGEID ?? 0,
          cost_price: Number(COSTPRICE ?? 0),
          register_cost: Number(REGISTERCOST ?? 0),
          act_cost: Number(ACTCOST ?? 0),
          transfer_cost: Number(TRANFERCOST ?? 0),
          usable: Boolean(USABLE),
        }

        if (!MODEL || !MOTORBIKE_TH || !MOTORBIKE_EN || !SIZE || !FRAMENUMBER) {
          validFileDataList.push(newObj)
        }

        return newObj
      })

      if (validFileDataList[0]) {
        this.gDisplayToast('โปรดตรวจสอบไฟล์อัปโหลด', 'พบข้อมูลบางตัวยังเป็นค่าว่างอยู่', 'danger')
      }
    },
    async onSaveUploadFile() {
      const confirm = await this.gCheckConfirmV2('ยืนยันอัปโหลดข้อมูลรถ')
      if (!confirm) return
      this.itemsUploadLoading = true

      const resp = await this.$http
        .post('api/finance-car-data/file', {
          json_lists: this.itemsUpload,
        })
        .catch(() => null)
      // console.log('onSaveUploadFile', resp)
      if (resp && resp.code === 200) {
        await this.getMotorbike()
        this.itemsUpload = [...resp.data].map((v, i) => ({ ...v, local_id: i }))
        if (resp.data.length > 0) {
          this.gDisplayToast(
            'ข้อมูลรถที่ถูกอัปโหลดมาไม่ถูกต้องบางส่วน',
            'โปรดแก้ไขแล้วทำการอัพโหลดใหม่อีกครั้ง',
            'danger',
          )
        }
      }
      this.itemsUploadLoading = false
    },
    onClickViewItemsUpload({ item }) {
      this.canEditForm = false
      this.formMotorbike = { ...item }
      this.$refs.refModalCreateLocal.show()
    },
    onClickDeleteItemsUpload({ item }) {
      this.itemsUpload = [...this.itemsUpload].filter(v => v.local_id !== item.local_id)
    },
    async onClickCancelUpload() {
      const confirm = await this.gCheckConfirmV2('ยกเลิกการอัปโหลด')
      if (!confirm) return
      this.itemsUpload = []
      this.fileUploadName = ''
    },
    onClickEditItemsUpload({ item }) {
      // console.log('onClickEditItemsUpload', item)
      this.canEditForm = true
      this.formMotorbike = { ...item }
      this.$refs.refModalCreateLocal.show()
    },
    async onModalLocalConfirm(bvEvent) {
      bvEvent.preventDefault()
      const validate = await this.$refs.refFormMotorbikeLocal.validateForm()
      if (!validate) return
      const form = this.$refs.refFormMotorbikeLocal.getFormData()
      // console.log('onModalLocalConfirm', form)
      this.itemsUpload = [...this.itemsUpload].map(v => {
        if (v.local_id === form.local_id) {
          return { ...form }
        }

        return { ...v }
      })
      this.$refs.refModalCreateLocal.hide()
    },
  },
}
</script>

<style lang="scss" scoped></style>
