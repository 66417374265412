<template>
  <div>
    <validation-observer
      v-if="form"
      ref="refFormCreate"
    >
      <b-form>
        <b-row>
          <b-col
            sm="12"
            lg="5"
          >
            <b-form-group label="โมเดล">
              <validation-provider
                #default="{ errors }"
                name="โมเดล"
                rules="required"
              >
                <b-form-input
                  v-model="form.car_model"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="5"
          >
            <b-form-group label="หมายเลขเฟรม">
              <validation-provider
                #default="{ errors }"
                name="หมายเลขเฟรม"
                rules="required"
              >
                <b-form-input
                  v-model="form.frame_number"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="2"
          >
            <b-form-group label="ไซส์">
              <validation-provider
                #default="{ errors }"
                name="ไซส์"
                rules="required"
              >
                <v-select
                  v-model="form.car_size"
                  :options="optionsSize"
                  :disabled="!canEdit"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="6"
          >
            <b-form-group label="ชื่อรถอังกฤษ">
              <validation-provider
                #default="{ errors }"
                name="ชื่อรถอังกฤษ"
                rules="required"
              >
                <b-form-input
                  v-model="form.car_name_en"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="6"
          >
            <b-form-group label="ชื่อรถไทย">
              <validation-provider
                #default="{ errors }"
                name="ชื่อรถไทย"
                rules="required"
              >
                <b-form-input
                  v-model="form.car_name_th"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="3"
          >
            <b-form-group label="ราคา">
              <validation-provider
                #default="{ errors }"
                name="ราคา"
                rules="required"
              >
                <input-currency-widget
                  v-model="form.selling_price"
                  :error="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="3"
          >
            <b-form-group label="ต้นทุน">
              <validation-provider
                #default="{ errors }"
                name="ต้นทุน"
                rules="required"
              >
                <input-currency-widget
                  v-model="form.cost_price"
                  :error="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="3"
          >
            <b-form-group label="ต้นทุนขนส่ง">
              <validation-provider
                #default="{ errors }"
                name="ต้นทุนขนส่ง"
                rules="required"
              >
                <input-currency-widget
                  v-model="form.transfer_cost"
                  :error="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="3"
          >
            <b-form-group label="รูป ID">
              <validation-provider
                #default="{ errors }"
                name="รูป ID"
              >
                <b-form-input
                  v-model="form.image_id"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="6"
          >
            <b-form-group label="ต้นทุน หมายเลขทะเบียน">
              <validation-provider
                #default="{ errors }"
                name="ต้นทุน หมายเลขทะเบียน"
                rules="required"
              >
                <input-currency-widget
                  v-model="form.register_cost"
                  :error="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="6"
          >
            <b-form-group label="ต้นทุน พรบ">
              <validation-provider
                #default="{ errors }"
                name="ต้นทุน พรบ"
                rules="required"
              >
                <input-currency-widget
                  v-model="form.act_cost"
                  :error="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="แสดงหน้าค้นหา">
              <validation-provider
                #default="{ errors }"
                name="แสดงหน้าค้นหา"
                rules="required"
              >
                <div class="d-flex mt-50">
                  <b-form-radio
                    v-model="form.usable"
                    name="แสดงหน้าค้นหา"
                    :value="true"
                    :disabled="!canEdit"
                    class="mr-2 custom-control-success"
                  >
                    เปิด
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.usable"
                    name="แสดงหน้าค้นหา"
                    :value="false"
                    :disabled="!canEdit"
                    class="custom-control-secondary"
                  >
                    ปิด
                  </b-form-radio>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <pre>{{ form }}</pre> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    formData: {
      type: Object,
      default: () => null,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: null,
      optionsSize: ['S', 'M', 'L'],
    }
  },
  watch: {
    formData(newVal) {
      this.form = { ...newVal }
    },
  },
  created() {
    this.form = { ...this.formData }
  },
  methods: {
    getFormData() {
      return this.form
    },
    async validateForm() {
      const v = await this.$refs.refFormCreate.validate()
      return v
    },
  },
}
</script>

<style lang="scss" scoped></style>
