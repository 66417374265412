var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.form)?_c('validation-observer',{ref:"refFormCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","lg":"5"}},[_c('b-form-group',{attrs:{"label":"โมเดล"}},[_c('validation-provider',{attrs:{"name":"โมเดล","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.car_model),callback:function ($$v) {_vm.$set(_vm.form, "car_model", $$v)},expression:"form.car_model"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3929630563)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"5"}},[_c('b-form-group',{attrs:{"label":"หมายเลขเฟรม"}},[_c('validation-provider',{attrs:{"name":"หมายเลขเฟรม","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.frame_number),callback:function ($$v) {_vm.$set(_vm.form, "frame_number", $$v)},expression:"form.frame_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1472978562)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"2"}},[_c('b-form-group',{attrs:{"label":"ไซส์"}},[_c('validation-provider',{attrs:{"name":"ไซส์","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optionsSize,"disabled":!_vm.canEdit,"clearable":false},model:{value:(_vm.form.car_size),callback:function ($$v) {_vm.$set(_vm.form, "car_size", $$v)},expression:"form.car_size"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2693948396)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"ชื่อรถอังกฤษ"}},[_c('validation-provider',{attrs:{"name":"ชื่อรถอังกฤษ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.car_name_en),callback:function ($$v) {_vm.$set(_vm.form, "car_name_en", $$v)},expression:"form.car_name_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2124835839)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"ชื่อรถไทย"}},[_c('validation-provider',{attrs:{"name":"ชื่อรถไทย","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.car_name_th),callback:function ($$v) {_vm.$set(_vm.form, "car_name_th", $$v)},expression:"form.car_name_th"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2416155592)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"ราคา"}},[_c('validation-provider',{attrs:{"name":"ราคา","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-currency-widget',{attrs:{"error":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.selling_price),callback:function ($$v) {_vm.$set(_vm.form, "selling_price", $$v)},expression:"form.selling_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3701437687)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"ต้นทุน"}},[_c('validation-provider',{attrs:{"name":"ต้นทุน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-currency-widget',{attrs:{"error":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.cost_price),callback:function ($$v) {_vm.$set(_vm.form, "cost_price", $$v)},expression:"form.cost_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1787572618)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"ต้นทุนขนส่ง"}},[_c('validation-provider',{attrs:{"name":"ต้นทุนขนส่ง","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-currency-widget',{attrs:{"error":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.transfer_cost),callback:function ($$v) {_vm.$set(_vm.form, "transfer_cost", $$v)},expression:"form.transfer_cost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1639576172)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"รูป ID"}},[_c('validation-provider',{attrs:{"name":"รูป ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":!_vm.canEdit},model:{value:(_vm.form.image_id),callback:function ($$v) {_vm.$set(_vm.form, "image_id", $$v)},expression:"form.image_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2387431292)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"ต้นทุน หมายเลขทะเบียน"}},[_c('validation-provider',{attrs:{"name":"ต้นทุน หมายเลขทะเบียน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-currency-widget',{attrs:{"error":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.register_cost),callback:function ($$v) {_vm.$set(_vm.form, "register_cost", $$v)},expression:"form.register_cost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,338740782)})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"ต้นทุน พรบ"}},[_c('validation-provider',{attrs:{"name":"ต้นทุน พรบ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-currency-widget',{attrs:{"error":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.act_cost),callback:function ($$v) {_vm.$set(_vm.form, "act_cost", $$v)},expression:"form.act_cost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1615466257)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"แสดงหน้าค้นหา"}},[_c('validation-provider',{attrs:{"name":"แสดงหน้าค้นหา","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex mt-50"},[_c('b-form-radio',{staticClass:"mr-2 custom-control-success",attrs:{"name":"แสดงหน้าค้นหา","value":true,"disabled":!_vm.canEdit},model:{value:(_vm.form.usable),callback:function ($$v) {_vm.$set(_vm.form, "usable", $$v)},expression:"form.usable"}},[_vm._v(" เปิด ")]),_c('b-form-radio',{staticClass:"custom-control-secondary",attrs:{"name":"แสดงหน้าค้นหา","value":false,"disabled":!_vm.canEdit},model:{value:(_vm.form.usable),callback:function ($$v) {_vm.$set(_vm.form, "usable", $$v)},expression:"form.usable"}},[_vm._v(" ปิด ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2624392680)})],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }